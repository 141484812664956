import { Injectable } from '@angular/core';
import { BaseService } from '@/services/base.service';
import { Observable } from 'rxjs';
import { FrameworkContractModel } from '@/models/framework-contract.model';

interface Framework {
  file: File;
  freelancerId: number;
  contractNumber: string;
  fromDate: string;
  toDate: string;
}

@Injectable({
  providedIn: 'root'
}) export class FrameworkContractsService {

  constructor(private baseService: BaseService) {}

  public getContracts(freelancerId?: string): Observable<FrameworkContractModel[]> {
    const params = { freelancerId };
    if (!freelancerId) {
      delete params.freelancerId;
    }
    return this.baseService.executeGet<FrameworkContractModel[]>('frameworkContract', { params });
  }

  public deleteContract(frameworkContractId: number): Promise<any> {
    return this.baseService.executeDelete<any>('frameworkContract', { params: { frameworkContractId } });
  }

  public postContract(body: Framework): Promise<FrameworkContractModel> {
    const formData = new FormData();
    formData.append('file', body.file, body.file.name);
    formData.append('freelancerId', `${body.freelancerId}`);
    formData.append('contractNumber', `${body.contractNumber}`);
    formData.append('fromDate', `${body.fromDate}`);
    formData.append('toDate', `${body.toDate}`);

    return this.baseService.executePost<FrameworkContractModel>('frameworkContract', formData);
  }

}
