import { FormContext } from '../modules/tpt-forms/base/form.context';
import { RegistrationFormModel } from '../models/registration-form.model';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from '../classes/constants';
import { TptValidators } from '../modules/tpt-forms/validators/tpt.validators';
import { minLengthWithTrim } from '@/validators/min-length-validator';

export class RegistrationForm extends FormContext<RegistrationFormModel> {

  static createForm(): RegistrationForm {
    const form = new UntypedFormGroup({
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$'),
        Validators.maxLength(50), minLengthWithTrim(6)
      ]),
      password: new UntypedFormControl(null, [
        Validators.required,
        TptValidators.passwordValidator('password'),
        Validators.minLength(Constants.PASSWORD_MIN_LENGTH),
      ]),
      // passwordConfirmation: new FormControl(null, [Validators.required, TptValidators.passwordConfirmation('password', 'passwordConfirmation')]),
      userAgreement: new UntypedFormControl(null, [Validators.required]),
      privacyPolicy: new UntypedFormControl(null, [Validators.required]),
      role: new UntypedFormControl(null, [Validators.required]),
    });

    return new RegistrationForm(form);
  }

  public get email(): AbstractControl {
    return this.getControl('email');
  }

  public get password(): AbstractControl {
    return this.getControl('password');
  }

  public get userAgreement(): AbstractControl {
    return this.getControl('userAgreement');
  }

  public get privacyPolicy(): AbstractControl {
    return this.getControl('privacyPolicy');
  }

  public get role(): AbstractControl {
    return this.getControl('role');
  }

  constructor(
    public form: UntypedFormGroup,
  ) {
    super(form);
  }

  public getFormData(): RegistrationFormModel {
    return this.form.value;
  }

}
