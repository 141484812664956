import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { ProfileModel } from '@/models/profile.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileManagementService {

  constructor(private baseService: BaseService) {}

  public createProfile(profile: Partial<ProfileModel>,
                       service: 'TALENT_CLOUD' | 'PAY_YOUR_TEAM' | 'CREATIVE_OBJECTS'): Promise<any> {
    return this.baseService.executePost<any>('v2/users/register', profile, {params: { service }});
  }

  public confirmRegistration(token: string): Promise<any> {
    return this.baseService.executePost<any>('v2/users/registrationConfirm', token);
  }

  public validateEmail(token: string): Promise<any> {
    return this.baseService.executePost<any>('v2/users/validateEmail', token);
  }

}
