<div>
  <div class="filters-header">
    <div class="fs-18 fw-700 text-black lh-24">Фильтры</div>
    <tc-button type="ghost" class="refresh" size="small" *ngIf="filtersNotEmpty()" (onClick)="refreshFilters()">
      <mat-icon [svgIcon]="svgIconsEnum.ArrowRefresh"></mat-icon>
      Сбросить
    </tc-button>
  </div>
  <div class="section">
    <div class="d-flex justify-between text-black cursor-pointer" (click)="filterExpanded.area = !filterExpanded.area">
      <div class="d-flex align-items-center gg-8">
        <div class="icon-wrap">
          <mat-icon [svgIcon]="svgIconsEnum.CategorySmall" class="w-14"></mat-icon>
          <div class="filter-badge" *ngIf="filterNotEmpty('categories')"></div>
        </div>
        <div class="fs-14 fw-600">{{'SEARCH.OPTIONS.AREA_OF_INTERESTS' | translate}}</div>
      </div>
      <mat-icon [svgIcon]="svgIconsEnum.PlusFilter" *ngIf="!filterExpanded.area"></mat-icon>
      <mat-icon [svgIcon]="svgIconsEnum.Minus" *ngIf="filterExpanded.area"></mat-icon>
    </div>

    <div class="categories pt-16" *ngIf="filterExpanded.area && categories.length">
      <section *ngFor="let category of categories; let i = index" class="category-section">
            <div class="list-section" [ngClass]="{'text-black': category.isSelected}">
              <tc-checkbox [indeterminate]="someChecked(category)"
                           [ngModel]="category.isSelected"
                           (ngModelChange)="setAll($event, category); getUpdAllSkills($event, category, null)"
              >
              </tc-checkbox>
              <span class="category-name" (click)="toggleSubcats(category)">{{getTranslation(category)}}</span>
            </div>
        <div *ngIf="category.expanded">
              <ul class="sublist-section">
                <li *ngFor="let subCategory of category.children" [ngClass]="{'text-black': subCategory.isSelected}">
                  <tc-checkbox [ngModel]="subCategory.isSelected"
                               (ngModelChange)="subCategoryChanged(subCategory, category);
                                getUpdAllSkills($event.checked, category, subCategory)"
                  >
                    {{getTranslation(subCategory)}}
                  </tc-checkbox>
                </li>
              </ul>
            </div>
      </section>
    </div>
  </div>
  <mat-divider class="divider"></mat-divider>
  <form [formGroup]="filter">
    <div class="skills-container section" *ngIf="allSkills.length">
      <div class="d-flex justify-between text-black cursor-pointer" (click)="filterExpanded.skills = !filterExpanded.skills">
        <div class="d-flex align-items-center gg-8">
          <div class="icon-wrap">
            <mat-icon [svgIcon]="svgIconsEnum.SubCategorySmall" class="w-14"></mat-icon>

            <div class="filter-badge" *ngIf="filterNotEmpty('skills')"></div>
          </div>
          <div class="fs-14 fw-600">{{'SEARCH.OPTIONS.SKILLS' | translate}}</div>
        </div>
        <mat-icon [svgIcon]="svgIconsEnum.PlusFilter" *ngIf="!filterExpanded.skills"></mat-icon>
        <mat-icon [svgIcon]="svgIconsEnum.Minus" *ngIf="filterExpanded.skills"></mat-icon>
      </div>
      <div class="skills pt-16" *ngIf="filterExpanded.skills">
          <mat-chip-listbox #chipList>
            <div class="input-wrapper" [class.has-value]="!!skillCtrl.value">
              <input
                #itemInput
                [formControl]="skillCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                maxlength="64"
              >
              <div class="label" (click)="itemInput.focus()">
                Навыки
              </div>
            </div>
          </mat-chip-listbox>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedSkill($event); itemInput.blur()">
            <mat-option *ngFor="let skill of filteredItems" [value]="skill">
              {{getTranslation(skill) || skill}}
            </mat-option>
          </mat-autocomplete>
        <div class="mt-12 chips">
          <tpt-chip type="gray"
                    *ngFor="let skill of filter.get('skills').controls; let i = index">
            <span class="chip-text"
                  [matTooltipDisabled]="(getTranslation(skill?.value) || skill?.value)?.length < 23"
                  [matTooltipShowDelay]="500"
                  [matTooltip]="getTranslation(skill?.value) || skill?.value">
              {{getTranslation(skill?.value) || skill?.value}}
            </span>
            <mat-icon [svgIcon]="svgIconsEnum.Close" (click)="removeSkill(i)"></mat-icon>
          </tpt-chip>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="d-flex justify-between text-black cursor-pointer" (click)="filterExpanded.expert = !filterExpanded.expert">
        <div class="d-flex align-items-center gg-8">
          <div class="icon-wrap">
            <mat-icon [svgIcon]="svgIconsEnum.SubCategorySmall" class="w-14"></mat-icon>
            <div class="filter-badge" *ngIf="filterNotEmpty('levels')"></div>
          </div>
          <div class="fs-14 fw-600">Уровень</div>
        </div>
        <mat-icon [svgIcon]="svgIconsEnum.PlusFilter" *ngIf="!filterExpanded.expert"></mat-icon>
        <mat-icon [svgIcon]="svgIconsEnum.Minus" *ngIf="filterExpanded.expert"></mat-icon>
      </div>

      <div class="checkbox-wrapper pt-16" *ngIf="filterExpanded.expert">
        <tc-checkbox [ngModel]="levelsFormControl.value.beginner"
                     [ngModelOptions]="{standalone: true}"
                     (ngModelChange)="levelsFormControl.controls.beginner.setValue($event)">
          {{'SEARCH.LEVEL.BEGINNER' | translate}}
        </tc-checkbox>
        <tc-checkbox [ngModel]="levelsFormControl.value.intermediate"
                     [ngModelOptions]="{standalone: true}"
                     (ngModelChange)="levelsFormControl.controls.intermediate.setValue($event)">
          {{'SEARCH.LEVEL.INTERMEDIATE' | translate}}
        </tc-checkbox>
        <tc-checkbox [ngModel]="levelsFormControl.value.master"
                     [ngModelOptions]="{standalone: true}"
                     (ngModelChange)="levelsFormControl.controls.master.setValue($event)">
          {{'SEARCH.LEVEL.MASTER' | translate}}
        </tc-checkbox>
      </div>
    </div>

    <div class="section" *ngIf="type === 'PROJECT' && budgetInfo && filterSliderForm">
      <div class="d-flex justify-between text-black cursor-pointer" (click)="filterExpanded.budget = !filterExpanded.budget">
        <div class="d-flex align-items-center gg-8">
          <div class="icon-wrap">
            <mat-icon [svgIcon]="svgIconsEnum.Ruble" class="w-14"></mat-icon>
            <div class="filter-badge" *ngIf="filterNotEmpty('budget')"></div>
          </div>
          <div class="fs-14 fw-600">
            Бюджет услуги
          </div>
        </div>
        <mat-icon [svgIcon]="svgIconsEnum.PlusFilter" *ngIf="!filterExpanded.budget"></mat-icon>
        <mat-icon [svgIcon]="svgIconsEnum.Minus" *ngIf="filterExpanded.budget"></mat-icon>
      </div>

      <div *ngIf="filterExpanded.budget" class="pt-16">
        <mat-slider *ngIf="budgetInfo" [step]="10"
          [min]="budgetInfo.minProjectAmount" [max]="budgetInfo.maxProjectAmount">
          <input [value]="filterSliderForm.value.min"

                 (valueChange)="startChange($event)" matSliderStartThumb>
          <input [value]="filterSliderForm.value.max"
                 (valueChange)="endChange($event)" matSliderEndThumb>
        </mat-slider>

        <div class="slider-inputs" *ngIf="filterSliderForm.controls">
          <tc-input [control]="filterSliderForm.get('min')"
                    mask="separator"
                    prefix="₽ ">
            <ng-container label>От</ng-container>
          </tc-input>
          <tc-input [control]="filterSliderForm.get('max')"
                    mask="separator"
                    prefix="₽ ">
            <ng-container label>До</ng-container>
          </tc-input>
        </div>
      </div>
    </div>

    <div class="location section">
      <div class="d-flex justify-between text-black cursor-pointer" (click)="filterExpanded.location = !filterExpanded.location">
        <div class="d-flex align-items-center gg-8">
          <div class="icon-wrap">
            <mat-icon [svgIcon]="svgIconsEnum.LocationOutline" class="w-14"></mat-icon>
            <div class="filter-badge" *ngIf="filterNotEmpty('countryCode')"></div>
          </div>
          <div class="fs-14 fw-600">Локация</div>
        </div>
        <mat-icon [svgIcon]="svgIconsEnum.PlusFilter" *ngIf="!filterExpanded.location"></mat-icon>
        <mat-icon [svgIcon]="svgIconsEnum.Minus" *ngIf="filterExpanded.location"></mat-icon>
      </div>

      <div class="pt-16" *ngIf="filterExpanded.location">
        <tc-location-dropdown [control]="filter.controls.countryCode"
                              [nameField]="'nameRu'"
                              [valueField]="'code'"
                              (searchOptions)="getCountries(false, $event)"
                              [options]="countries">
          <ng-container label>{{'SETTINGS.LOCATION' | translate}}</ng-container>
        </tc-location-dropdown>
      </div>
    </div>
  </form>
</div>
