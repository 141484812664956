import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { lastValueFrom, Subject } from 'rxjs';

import { DialogComponent } from '@/modules/dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { ProfileModel } from '@/models/profile.model';
import { ProfileService } from '@/services/profile.service';
import { SpinnerService } from '@/modules/tpt-ui/services/spinner.service';
import { SearchService } from '@/services/search.service';
import { ProjectApiService } from '@/services/project.api.service';
import { DataLayerService } from '@/services/data-layer.service';
import { RouteHelper } from '@/helpers/route.helper';
import { CategoryService } from '@/services/category.service';
import { CategoryV2Model } from '@/models/category-v2.model';
import { ProfileFilledService } from '@/services/profile-filled.service';
import { EditProfileService } from '@/modules/edit-profile/services/edit-profile.service';
import { JobInviteErrorDialogComponent } from '@/modules/common-dialogs/components/job-invite-error-dialog/job-invite-error-dialog.component';
import { FrameworkContractsService } from '@/services/framework-contracts.service';
import {
  SelectContractDialogComponent
} from '@/modules/common-dialogs/components/select-contract-dialog/select-contract-dialog.component';
import { WarningDialogComponent } from '@/modules/common-dialogs/components/warning-dialog/warning-dialog.component';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hire-freelancer-dialog',
  templateUrl: './hire-freelancer-dialog.component.html',
  styleUrls: ['./hire-freelancer-dialog.component.scss']
})
export class HireFreelancerDialogComponent implements OnInit {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @ViewChild(JobInviteErrorDialogComponent)
  public jobInviteErrorDialogComponent: JobInviteErrorDialogComponent;

  @ViewChild(SelectContractDialogComponent)
  public selectContractDialog: SelectContractDialogComponent;

  @ViewChild(WarningDialogComponent) warningDialogComponent: WarningDialogComponent;

  @Output() successfullyHired = new EventEmitter();

  public freelancer: ProfileModel;

  public svgIconsEnum = SvgIconsEnum;

  public vacancies: any[] = [];

  public currentProjectForm = new FormControl(null);

  public detailsOpened = false;

  public categories: CategoryV2Model[];

  public fullProjectInfo = null;

  private readonly destroy = new Subject<void>();

  private className = 'hire-freelancer-dialog';

  private config: MatDialogConfig = {
    width: '729px',
    minHeight: '200px',
    maxHeight: '100vh',
  };

  private freelancerContracts = [];

  constructor(private profileService: ProfileService,
              private spinner: SpinnerService,
              private searchService: SearchService,
              private editProfileService: EditProfileService,
              public projectService: ProjectApiService,
              public routeHelper: RouteHelper,
              private router: Router,
              private dataLayerService: DataLayerService,
              private categoryService: CategoryService,
              private translate: TranslateService,
              private frameworkService: FrameworkContractsService,
  ) {
  }

  ngOnInit() {
    this.categoryService.categoriesSubject.subscribe({
      next: (categories) => {
        this.categories = categories;
      }
    });
  }

  public open(freelancer, vacancies): void {
    this.vacancies = vacancies;
    this.freelancer = freelancer;
    this.currentProjectForm.setValue(null);
    this.getFreelancerContracts();

    this.currentProjectForm.valueChanges.pipe(takeUntil(this.destroy)).subscribe((value) => {
      this.getProject(value.projectId);
    });

    if (this.vacancies?.length === 1) {
      this.currentProjectForm.setValue(this.vacancies[0]);
    }

    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public getFreelancerContracts(): void {
    lastValueFrom(this.frameworkService.getContracts(this.freelancer.id)).then((contracts) => {
      this.freelancerContracts = contracts;
    });
  }

  public close(): void {
    this.destroy.next();
    this.destroy.complete();
    this.dialog.close();
    this.currentProjectForm.reset();
  }

  public hireFreelancer = async (): Promise<void> => {
    const legalInfo = await lastValueFrom(this.editProfileService.getUserLegal());
    const isInvalid = ProfileFilledService.clientDataInvalid(this.profileService.currentProfile, legalInfo);

    if (isInvalid) {
      this.jobInviteErrorDialogComponent.open();
      return;
    }

    if (this.freelancerContracts?.length) {
      const contracts = this.freelancerContracts;
      const projectStartDate = this.fullProjectInfo.startDate ? moment(this.fullProjectInfo.startDate) : null;
      const projectEndDate = this.fullProjectInfo.deadline ? moment(this.fullProjectInfo.deadline) : null;

      if (projectStartDate && projectEndDate) {
        const hasAppropriateContracts = contracts.some(contract => {
          const contractFromDate = moment(contract.fromDate);
          const contractToDate = moment(contract.toDate);

          return contractFromDate.isSameOrAfter(projectStartDate) && contractToDate.isSameOrBefore(projectEndDate);
        });

        if (hasAppropriateContracts) {
          this.selectContractDialog.open();
          return;
        }
      } else if (!projectStartDate && projectEndDate) {
        const hasAppropriateContracts = contracts.some(contract => {
          const contractFromDate = moment(contract.fromDate);
          const contractToDate = moment(contract.toDate);

          return contractFromDate.isBefore(projectEndDate) && contractToDate.isSameOrAfter(projectEndDate);
        });

        if (hasAppropriateContracts) {
          this.selectContractDialog.open();
          return;
        }
      }
    }

    this.spinner.startSpinner();
    const currentProject = this.currentProjectForm.value;
    this.searchService.sendInvite(this.freelancer.id, currentProject.projectId)
      .then(res => {
        if (res) {
          this.freelancer?.jobParticipating?.push(
            {
              projectId: currentProject.projectId,
              projectName: currentProject.projectName,
              jobName: currentProject.jobName,
              jobParticipationState: 'INVITED',
              jobState: currentProject.jobState,
              currentUserJobChatId: res.id
            }
          );
          this.successfullyHired.emit(this.freelancer.id);
        }
        this.close();
        this.spinner.stopSpinner();
      })
      .catch((error) => {
        if (error.errorCode.message === 'SERVER_ERRORS.PAYMENT_AMOUNT_MORE_THAN_LIMIT') {
          this.warningDialogComponent.open('Вы не можете пригласить исполнителя на этот проект',
            'Стоимость проекта превысит лимит дохода самозанятого в этом календарном году',
            'Закрыть',
            SvgIconsEnum.ErrIcon);
        }
        this.spinner.stopSpinner();
      });
  }

  public selectContractType(type: string): void {
    this.spinner.startSpinner();
    const currentProject = this.currentProjectForm.value;
    this.searchService.sendInvite(this.freelancer.id, currentProject.projectId)
      .then(res => {
        if (res) {
          this.freelancer?.jobParticipating.push(
            {
              projectId: currentProject.projectId,
              projectName: currentProject.projectName,
              jobName: currentProject.jobName,
              jobParticipationState: 'INVITED',
              jobState: currentProject.jobState,
              currentUserJobChatId: res.id
            }
          );
          this.successfullyHired.emit(this.freelancer.id);
        }

        const body = {
          chatId: res.id,
          frameworkContractId: type === 'FRAMEWORK' ? this.freelancerContracts[0]?.id : null
        };
        return lastValueFrom(this.searchService.setFrameworkContract(body));
      })
      .then((res) => {
        this.close();
        this.spinner.stopSpinner();
      })
      .catch((error) => {
        if (error.errorCode.message === 'SERVER_ERRORS.PAYMENT_AMOUNT_MORE_THAN_LIMIT') {
          this.warningDialogComponent.open('Вы не можете пригласить исполнителя на этот проект',
            'Стоимость проекта превысит лимит дохода самозанятого в этом календарном году',
            'Закрыть',
            SvgIconsEnum.ErrIcon
            );
        }
        this.spinner.stopSpinner();
      });
  }

  public category(id): string {
    const category: CategoryV2Model = this.categories.find((_: CategoryV2Model) => _.id === id);
    if (category) {
      return this.translate.currentLang === 'ru' ? category.nameRu : category.name;
    }
    return 'CREATE_PROJECT.STEP5.CATEGORY_NOT_SELECTED';
  }

  public subcategory(id, subId?: string): string {
    const category: CategoryV2Model = this.categories.find((_: CategoryV2Model) => _.id === id);
    if (!category) {
      return 'CREATE_PROJECT.STEP5.SUBCATEGORY_NOT_SELECTED';
    }

    const subCategory: CategoryV2Model = category.children.find((_: CategoryV2Model) => _.id === subId);
    if (subCategory) {
      return this.translate.currentLang === 'ru' ? subCategory.nameRu : subCategory.name;
    }
    return 'CREATE_PROJECT.STEP5.SUBCATEGORY_NOT_SELECTED';
  }

  public disabledJob(project): boolean {
    const allTalentJobs = this.freelancer?.jobParticipating?.filter(item =>
      ['INVITED', 'PROPOSAL_SUBMITTED'].includes(item.jobParticipationState)
    ).map(item => item.projectId);

    return allTalentJobs?.some(item => project.projectId === item);
  }

  public goToChat(job): void {
    const allTalentJobs = this.freelancer?.jobParticipating?.filter(
      item => ['INVITED', 'PROPOSAL_SUBMITTED'].includes(item.jobParticipationState)
    ).filter(item => item.jobState === 'INIT');

    const foundJob = allTalentJobs.find(item => item.projectId === job.projectId);

    if (!foundJob) {
      return;
    }

    const chatId = foundJob.currentUserJobChatId;

    this.router.navigate(this.routeHelper.contractsProposalsUrl(chatId));
    this.close();
  }

  public getProject = async (id: string): Promise<void> => {
    if (!id) {
      return;
    }

    this.fullProjectInfo = await lastValueFrom(this.projectService.getProjectById(id));
  }

}
