import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Button } from '../../../classes/button';
import { SvgIconsEnum } from '@/types/svg-icons.enum';

@Component({
  styleUrls: ['./alert-dialog.component.scss'],
  templateUrl: './alert-dialog.component.html',
})

export class AlertDialog {
  public message: string;
  public title: string;
  public params: any = {};
  public type: string;
  public buttons: Button[];
  public reason: string = null;
  public svgIconsEnum = SvgIconsEnum;

  constructor(public dialogRef: MatDialogRef<AlertDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
    this.title = data.title;
    this.params = data.params;
    this.type = data.type;
    this.buttons = data.buttons;
  }

}
