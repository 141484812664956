import { Injectable } from '@angular/core';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class AuthHelperService {
  constructor(private baseService: BaseService) {}

  public forgotPassword(username: string, service: string = 'TALENT_CLOUD'): Promise<void> {
    return this.baseService.executePost<void>('v2/users/forgotPassword', username, { params: { service } });
  }

  public changePassword(token: string, newPassword: string, newPasswordConfirm: string): Promise<void> {
    return this.baseService.executePost<void>('v2/users/restorePassword', {
      token,
      newPassword,
      newPasswordConfirm,
    });
  }
}
