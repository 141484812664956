import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Country } from '../models/country';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class CountryService {

  constructor(private baseService: BaseService) {}

  public getCountriesV2(name?: string): Observable<Country[]> {
    return this.baseService.executeGet<Country[]>('v2/countries', name ? {params: {name}} : {})
      .pipe(tap((countries) => countries.unshift({
        name: 'Irrelevant',
        nameRu: 'Не выбрано',
        code: 'Irrelevant',
        id: null
      })));
  }

}
