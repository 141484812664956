import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { ActivatedRoute } from '@angular/router';

import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { ProfileService } from '@/services/profile.service';
import { PortalService } from '@/services/portal.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthV2Service } from '@/services/auth/authV2.service';

@Component({
  selector: 'tpt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit, OnDestroy {

  @Output() toggleMobileSidebar = new EventEmitter<void>();

  public headerPortal: TemplatePortal | null = null;

  public isDark: boolean;

  public svgIconsEnum = SvgIconsEnum;

  private readonly destroy$ = new Subject<void>();

  constructor(
    public profileService: ProfileService,
    private portalService: PortalService,
    private cdf: ChangeDetectorRef,
    public activatedRoute: ActivatedRoute,
    public authV2Service: AuthV2Service,
  ) {
  }

  public ngAfterViewInit(): void {
    this.portalService.headerPortal.pipe(
      takeUntil(this.destroy$),
    ).subscribe((portal: TemplatePortal) => {
      this.headerPortal = portal;
      this.cdf.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public logout(): void {
    this.authV2Service.logout();
  }
}
