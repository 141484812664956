import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { RouteHelper } from '../../helpers/route.helper';
import { SvgIconsEnum } from '../../types/svg-icons.enum';
import { PortalService } from '../../services/portal.service';
import { ProfileService } from '../../services/profile.service';
import { ProjectApiService } from '../../services/project.api.service';
import { ContractsService } from '../../modules/contracts/services/contracts.service';
import { NewWsService } from '../../services/new-ws.service';
import { Subject } from 'rxjs';
import { FrameworkContractModel } from '@/models/framework-contract.model';
import { StateHelperService } from '@/services/state-helper.service';
import { StatesService } from '@/services/states.service';
import { MassPaymentsService } from '@/services/mass-payments.service';

@Component({
  selector: 'tpt-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [ './sidebar.component.scss' ],
})
export class SidebarComponent implements AfterViewInit, OnDestroy {

  public get isEmployer(): boolean {
    if (!this.profileService.currentProfile) { return; }
    return this.profileService.currentProfile.isEmployer();
  }

  public svgIconsEnum = SvgIconsEnum;

  public sidebarPortal: TemplatePortal | null = null;

  public expanded: boolean;

  public extendedSidebar: boolean;

  public unreadCount = 0;

  public unreadPaymentsCount = 0;

  public contracts: FrameworkContractModel[] = [];

  private timeToGetChangesFromDOM = 50;

  private readonly destroy$ = new Subject<void>();

  constructor(
    public routeHelper: RouteHelper,
    public profileService: ProfileService,
    private projectService: ProjectApiService,
    private portalService: PortalService,
    private cdf: ChangeDetectorRef,
    private router: Router,
    private contractsService: ContractsService,
    private newWs: NewWsService,
    private stateHelper: StateHelperService,
    private statesService: StatesService,
    private massPaymentsService: MassPaymentsService,
  ) { }

  get projectsRoute() {
    return this.routeHelper.employerProjectsDashboard;
  }

  // a temporary solution, since routerLinkActive does not work properly with child routes here
  get searchIsActive(): boolean {
    return this.router.url.includes('/search');
  }

  ngAfterViewInit(): void {
    this.portalService.sidebarPortal.pipe(
      takeUntil(this.destroy$),
    ).subscribe(this.handleSidebarPortal);

    this.profileService.startOnBoardingSubject.pipe(takeUntil(this.destroy$)).subscribe(() => this.expanded = true);

    if (this.profileService.currentProfile) {
      this.getUnreadMessages();

      if (this.profileService.currentProfile.isEmployer()){
        this.getActivePaymentsCount();
      }
    }

    this.newWs.updateUnreadCount.subscribe(senderId => {
      if (senderId === this.profileService.currentProfile?.id) {
        return;
      }

      this.getUnreadMessages();
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getUnreadMessages(): void {
    this.contractsService.getUnreadCount().pipe(takeUntil(this.destroy$)).subscribe(count => {
      this.unreadCount = count;
    });
  }

  public getActivePaymentsCount(): void {
    this.massPaymentsService.getMassPaymentsActivesCount().pipe(takeUntil(this.destroy$))
      .subscribe({
        next: count => {
          this.unreadPaymentsCount = count;
        },
        error: (err) => {},
  });
  }

  public toggleSidebar(): void {
    this.expanded = !this.expanded;
    this.stateHelper.desktopSidebarOpened.next(this.expanded);
  }

  private handleSidebarPortal = (templatePortal: TemplatePortal): void => {
    this.extendedSidebar = Boolean(templatePortal);

    if (this.extendedSidebar && this.expanded) {
      this.expanded = false;
      this.statesService.sidebarExpanded.next(this.expanded);
    }
    this.cdf.detectChanges();

    setTimeout(() => {
      this.sidebarPortal = templatePortal;
      this.cdf.detectChanges();
    }, this.timeToGetChangesFromDOM);
  }
}
