import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { SkillV2Model } from '@/models/skill-v2.model';

@Injectable({
  providedIn: 'root',
})
export class SkillService {

  constructor(private baseService: BaseService) {}


  public getSkillsV2(): Observable<SkillV2Model[]> {
    return this.baseService.executeGet<SkillV2Model[]>('v2/skills');
  }

  public getCategorySkillsV2(categoryId: string, prefix?: string): Observable<SkillV2Model[]> {
    const params = { prefix, categoryId };
    if (!params.prefix) {
      delete params.prefix;
    }

    if (!params.categoryId) {
      delete params.categoryId;
    }
    return this.baseService.executeGet<SkillV2Model[]>('v2/skills', { params });
  }
}
