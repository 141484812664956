import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minLengthWithTrim(minLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value.length === 0) {
      return null;
    }
    const value = control.value.trim();

    const length: number = value ? value.length : 0;
    return length < minLength ?
      {minlength: {requiredLength: minLength, actualLength: length}} :
      null;
  };
}
