import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LogLevel } from '../classes/enums';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  // tslint:disable:no-console
  public logLevel: LogLevel;
  public logServerLevel: LogLevel;

  constructor(private baseService: BaseService) {

    if (environment.LOG_SETTINGS.GLOBAL) {
      (window as any).logService = this;
    }

    this.logLevel = environment.LOG_SETTINGS.LOG_LEVEL;
    this.logServerLevel = environment.LOG_SETTINGS.LOG_SERVER_LEVEL;
  }

  public error(message: any) {
    const m = this.getMessage(message);
    if (this.logLevel >= LogLevel.ERROR) {
      console.error.apply(console, [m]);

      if (this.logServerLevel >= LogLevel.ERROR) {
        this.baseService.executePost<any>('logs/error', m);
      }
    }
  }

  public warn(message: any) {
    const m = this.getMessage(message);
    if (this.logLevel >= LogLevel.WARN) {
      console.warn.apply(console, [m]);

      if (this.logServerLevel >= LogLevel.WARN) {
        this.baseService.executePost<any>('logs/warn', m);
      }
    }
  }

  public info(message: any) {
    const m = this.getMessage(message);
    if (this.logLevel >= LogLevel.INFO) {
      console.info.apply(console, [m]);

      if (this.logServerLevel >= LogLevel.INFO) {
        this.baseService.executePost<any>('logs/info', m);
      }
    }
  }

  private getMessage(message: any): string {
    const m = typeof message === 'string' ? message : JSON.stringify(message);
    const error = new Error();
    if (error && error.stack) {
      let stackTrace = error.stack.replace(/^Error\s*at\s*/, '');
      stackTrace = stackTrace.replace(/\s*(at)*\s*LogService.push[^\n]*/g, '');
      return `${m} ${stackTrace}`;
    }
    return m;
  }
}
