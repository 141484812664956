import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from './classes/enums';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { RegisterComponent } from './components/register/register.component';
import { RoleGuard } from './guards/role.guard';
import { RouteHelper } from './helpers/route.helper';
import { MainPageGuard } from './guards/main-page.guard';
import { ConfirmSuccessComponent } from './components/confirm-success/confirm-success.component';
import { AcceptTcComponent } from '@/components/accept-tc/accept-tc.component';
import { AuthGuard } from '@/guards/auth.guard';
import { Constants } from '@/classes/constants';
import { RedirectComponent } from '@/components/redirect.component';

const routes: Routes = [
  // { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'register', component: RegisterComponent, canActivate: [MainPageGuard], title: 'Регистрация' },
  { path: 'confirm', component: ConfirmComponent },
  { path: 'confirm-success', component: ConfirmSuccessComponent, title: 'Аккаунт успешно подтвержден' },
  { path: 'confirm/connect', component: ConfirmComponent },
  {
    path: 'creative-objects',
    loadChildren: () => import('./creative-objects/creative-objects.routes')
      .then((m) => m.routes),
  },
  // {
  //   path: RouteHelper.PAYYOURTEAM,
  //   loadChildren: () => import('./modules/pay-your-team/pay-your-team.module').then(m => m.PayYourTeamModule)
  // },
  {
    path: RouteHelper.SETTINGS_PATH,
    loadChildren: () => import('./modules/edit-profile/edit-profile.module').then((m) => m.EditProfileModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.FREELANCER, Role.EMPLOYER], service: Constants.TC },
    title: 'Настройки',
  },
  {
    path: RouteHelper.PROJECTS_DASHBOARD_PATH,
    loadChildren: () => import('./modules/employer/jobs.module').then((m) => m.JobsModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.EMPLOYER, Role.FREELANCER], service: Constants.TC },
    title: 'Проекты',
  },
  {
    path: RouteHelper.CONTRACTS_PATH,
    loadChildren: () => import('./modules/contracts/contracts.module').then((m) => m.ContractsModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.EMPLOYER, Role.FREELANCER], service: Constants.TC },
    title: 'Сообщения'
  },
  {
    path: 'finance',
    loadChildren: () => import('./modules/finance/finance.module').then((m) => m.FinanceModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.FREELANCER, Role.EMPLOYER], service: Constants.TC },
    title: 'Финансы'
  },
  {
    path: 'search',
    loadChildren: () =>
    import('./modules/search/search.module').then((m) => m.SearchModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.EMPLOYER, Role.FREELANCER], service: Constants.TC },
    title: 'Поиск',
  },
  {
    path: 'profiles',
    loadChildren: () =>
      import('./modules/user/user.module').then((m) => m.UserModule)
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('./modules/projects/projects.module').then((m) => m.ProjectsModule)
  },
  {
    path: RouteHelper.EXECUTORS,
    loadChildren: () =>
      import('./modules/executors/executors.module').then((m) => m.ExecutorsModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.EMPLOYER], service: Constants.TC },
    title: 'Мои исполнители',
  },
  {
    path: RouteHelper.PAYMENTS,
    loadChildren: () =>
      import('./modules/payments/payments.module').then((m) => m.PaymentsModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.FREELANCER], service: Constants.TC },
    title: 'Выплаты',
  },
  {
    path: RouteHelper.CLIENTS,
    loadChildren: () =>
      import('./modules/clients/clients.module').then((m) => m.ClientsModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.FREELANCER], service: Constants.TC },
    title: 'Мои заказчики'
  },
  {
    path: RouteHelper.MASS_PAYMENTS,
    loadChildren: () =>
      import('./modules/mass-payments/mass-payments.module').then((m) => m.MassPaymentsModule),
    canActivate: [RoleGuard],
    data: { expectedRoles: [Role.EMPLOYER, Role.FREELANCER], service: Constants.TC },
    title: 'Выплаты',
  },
  { path: 'accept', component: AcceptTcComponent, canActivate: [AuthGuard] },
  { path: '', component: RedirectComponent },
  { path: '**', redirectTo: 'jobs' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
