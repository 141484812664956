<tpt-label *ngIf="showLabel">
  <ng-content select="[label]"></ng-content>
</tpt-label>

<mat-form-field
  appearance="outline"
  [class.-error-state]="errorState"
>
  <input
    matInput
    [mask]="mask"
    [formControl]="control"
    [placeholder]="placeholder"
    [type]="type"
    (keyup)="keyup.emit()"
    (blur)="onBlur.emit()"
    (focus)="onFocus.emit()"
    [class.uppercase] = uppercase
  />

  <mat-hint [align]="hintAlign">
    <ng-content select="[hint]"></ng-content>
  </mat-hint>

  <mat-error [class.-invisible]="!errorState">
    {{ error | translate}}
  </mat-error>

  <ng-content select="[icon]"></ng-content>
</mat-form-field>
