import {
  ChangeDetectorRef,
  Component, ElementRef, HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';

import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { SkillService } from 'src/app/services/skill.service';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { SearchService } from '@/services/search.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { ProfileModel } from '@/models/profile.model';
import { LoadingBarService } from '@/services/loading-bar.service';
import { TalentFiltersComponent } from '@/modules/search/components/parts/talent-filters/talent-filters.component';
import {
  FilterSearchDialogComponent
} from '@/modules/search/components/dialogs/filter-search-dialog/filter-search-dialog.component';

@Component({
  selector: 'freelancer-search',
  templateUrl: './freelancer-search.component.html',
  styleUrls: ['./../../filters-common.scss'],
})
export class FreelancerSearchComponent implements OnInit, OnDestroy {

  @ViewChild('top')
  public top: ElementRef;

  @ViewChild(TalentFiltersComponent)
  public talentFiltersComponent: TalentFiltersComponent;
  @ViewChild(FilterSearchDialogComponent) filterSearchDialog: FilterSearchDialogComponent;

  public freelancers: ProfileModel[] = [];
  public svgIconsEnum = SvgIconsEnum;

  public filter: FormGroup;
  public showSkeleton = false;
  public loading: boolean;
  public totalElements: number;

  private pageNumber = 0;
  private params;
  private currentPosition = 0;
  private totalPages: number;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private searchService: SearchService,
    private skillService: SkillService,
    private cdr: ChangeDetectorRef,
    private spinner: SpinnerService,
    private translate: TranslateService,
    private loadingBarService: LoadingBarService
  ) {}

  @HostListener('scroll', ['$event']) onScroll(event) {
    const containerHeight = event?.target?.offsetHeight;
    const scrollHeight = event?.target?.scrollHeight;
    const scrollTop = event?.target?.scrollTop;

    if (this.currentPosition > scrollTop) {
      return;
    }
    this.currentPosition = scrollTop;

    const a = scrollHeight - scrollTop;
    const b = containerHeight * 0.25 + containerHeight;

    if (a < b && !this.loading) {
      if (this.pageNumber >= this.totalPages - 1) {
        return;
      }
      this.loading = true;
      this.pageNumber = this.pageNumber + 1;
      this.getListOfFreelancers(this.params);
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.loadingBarService.start();
    this.getFilteredFreelancers();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public updateUsers(val): void {
    this.filter.controls.name.patchValue(val);
  }

  openFilterDialog() {
    this.filterSearchDialog.open(this.filter, 'TALENT');
  }

  private getListOfFreelancers(filter): void {
    const defaultFilter = {pageNumber: this.pageNumber, pageSize: 15};
    this.searchService.getListOfFreelancers({...defaultFilter, ...filter}).subscribe((res) => {
      this.freelancers = [...this.freelancers, ...res.content];
      this.totalPages = res.totalPages;
      this.totalElements = res.totalElements;
      this.loading = false;
      this.showSkeleton = false;
      this.loadingBarService.complete();
    });
  }

  private getFilteredFreelancers(): void {
    this.searchService.freelancers$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.refreshVariables();

      if (!data) {
        this.params = {};
        this.getListOfFreelancers({});
        return;
      }

      const mappedData = data.value;
      mappedData.skills = mappedData.skills.map(item => item.id);

      const model: { [key: string]: any } = {};
      Object.keys(mappedData).forEach(key => {
        const value = mappedData[key];
        if (Boolean(value) && !(Array.isArray(value) && value.length === 0)) {
          model[key] = value;
        }
      });

      this.params = model;
      this.getListOfFreelancers(model);
    });
  }

  private refreshVariables(): void {
    this.pageNumber = 0;
    this.currentPosition = 0;
    this.showSkeleton = true;
    this.freelancers = [];
    this.top?.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  private initForm(): void {
    this.filter = new FormGroup({
      categories: new FormControl([]),
      skills: new FormArray([]),
      levels: new FormControl(''),
      countryCode: new FormControl(''),
      languageLevels: new FormControl([]),
      name: new FormControl(''),
    });

    this.searchService.filtersFreelancersNext(this.filter);
    this.filter.valueChanges.pipe(
      debounceTime(500),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.searchService.filtersFreelancersNext(this.filter);
    });
  }

}
