import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LabelComponent } from './components/label/label.component';
import { InputComponent } from './components/input/input.component';
import { TptControlDirective } from './directives/tpt-control.directive';
import { SelectComponent } from './components/select/select.component';
import { MaterialModule } from '../material/material.module';
import { TextareaComponent } from './components/textarea/textarea.component';
import { DatepickerHeaderComponent } from './components/datepicker-header/datepicker-header.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { TranslateModule } from '@ngx-translate/core';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { TcInputComponent } from './tc-components/tc-input/tc-input.component';
import { TcDropdownComponent } from './tc-components/tc-dropdown/tc-dropdown.component';
import { CommonDirectivesModule } from '@/modules/common-directives/common-directives.module';
import { TcTextareaComponent } from './tc-components/tc-textarea/tc-textarea.component';
import { TcTelInputComponent } from './tc-components/tc-tel-input/tc-tel-input.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { TcButtonComponent } from './tc-components/tc-button/tc-button.component';
import { TcDatepickerComponent } from './tc-components/tc-datepicker/tc-datepicker.component';
import {
  TcAddressAutocompleteComponent
} from './tc-components/tc-address-autocomplete/tc-address-autocomplete.component';
import {
  TcDateRangePickerComponent
} from '@/modules/tpt-forms/tc-components/tc-date-range-picker/tc-date-range-picker.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import {
  ExecutorsAutocompleteComponent
} from './tc-components/executors-autocomplete/executors-autocomplete.component';
import { TcSlideToggleComponent } from './tc-components/tc-slide-toggle/tc-slide-toggle.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { LocationDropdownComponent } from './tc-components/location-dropdown/location-dropdown.component';
import {
  ProjectDropdownComponent
} from '@/modules/tpt-forms/tc-components/project-dropdown/project-dropdown.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    CommonDirectivesModule,
    NgxMatIntlTelInputComponent,
    MatAutocompleteModule,
    MatSlideToggleModule,
    OverlayModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  declarations: [
    TptControlDirective,
    LabelComponent,
    InputComponent,
    SelectComponent,
    TextareaComponent,
    DatepickerHeaderComponent,
    DatepickerComponent,
    SelectSearchComponent,
    TcInputComponent,
    TcDropdownComponent,
    TcTextareaComponent,
    TcTelInputComponent,
    TcButtonComponent,
    TcDatepickerComponent,
    TcAddressAutocompleteComponent,
    TcDateRangePickerComponent,
    SearchInputComponent,
    ExecutorsAutocompleteComponent,
    TcSlideToggleComponent,
    LocationDropdownComponent,
    ProjectDropdownComponent,
  ],
  exports: [
    LabelComponent,
    InputComponent,
    SelectComponent,
    TextareaComponent,
    DatepickerHeaderComponent,
    DatepickerComponent,
    SelectSearchComponent,
    TcInputComponent,
    TcDropdownComponent,
    TcTextareaComponent,
    TcButtonComponent,
    TcDatepickerComponent,
    TcAddressAutocompleteComponent,
    TcDateRangePickerComponent,
    SearchInputComponent,
    ExecutorsAutocompleteComponent,
    TcSlideToggleComponent,
    LocationDropdownComponent,
    ProjectDropdownComponent,
  ],
  providers: [
    provideNgxMask(),
  ]
})
export class TptFormsModule {
}
