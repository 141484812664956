<div class="wrapper">
  <div class="d-flex gg-12 search-row" *ngIf="searchControl">
    <tc-input [control]="searchControl"
              (keyup.enter)="searchProjects()"
              [icon]="svgIconsEnum.Search"
    >
      <ng-container label>Поиск по названию проекта </ng-container>
    </tc-input>
    <tc-button type="secondary" class="mobile" size="x-large" (onClick)="openFilterDialog.emit()">
      <mat-icon [svgIcon]="svgIconsEnum.FilterList"></mat-icon>
    </tc-button>
  </div>

  <div class="d-flex justify-between align-items-center mb-16" *ngIf="!showSkeleton">
    <div class="fs-14 fw-500 text-gray-600 count-label">
      {{'SEARCH.PROJECTS_PLURAL' | translate:{count: totalElements} }}</div>

    <div class="sort-row">
      Сортировать по:
      <div class="text-green sort-button desc"
           *ngIf="sortDirection === 'PUBLISH_DATE_DESC'"
           (click)="sortDirection = 'PUBLISH_DATE_ASC'; emitSortChange.emit('PUBLISH_DATE_ASC')">
        Дате (сначала новые)
        <mat-icon [svgIcon]="svgIconsEnum.ArrowForward"></mat-icon>
      </div>
      <div class="text-green sort-button asc"
           *ngIf="sortDirection === 'PUBLISH_DATE_ASC'"
           (click)="sortDirection = 'PUBLISH_DATE_DESC'; emitSortChange.emit('PUBLISH_DATE_DESC')">
        Дате (сначала старые)
        <mat-icon [svgIcon]="svgIconsEnum.ArrowForward"></mat-icon>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!showSkeleton && categories?.length; else projectsLoader">
    <div *ngIf="disableButton" class="info-message mb-24">
      <mat-icon class="info-icon" [svgIcon]="svgIconsEnum.InfoIcon"></mat-icon>
      <div>
        Для отправки заявки на&nbsp;участие в&nbsp;проектах выполните привязку профиля к&nbsp;ФНС.
      </div>
    </div>
    <ng-container *ngIf="projects.length; else notFound">
        <mat-card *ngFor="let project of projects" class="mb-24 job-card"
                  [class.visited]="project?.visited"
                  (click)="openCardDetails(project)">
          <div class="header-row">
            <div class="fs-12 fw-500 text-gray-600" *ngIf="project?.parentCategoryId">{{getProjectCategory(project)}}
              <ng-container *ngIf="project?.subCategoryId">/{{getProjectSubCategory(project)}}</ng-container>
            </div>
            <div class="fs-12 fw-500 text-gray-600 desktop">{{'SEARCH.PUBLISHED' | translate}} {{project.publishDate | timeAgo}}</div>
          </div>
          <div class="fs-18 fw-700 text-gray-800 mb-8">{{project?.projectName}}</div>
          <div class="description mb-32">{{project?.description | tptTruncate: 220}}</div>

            <div class="">
              <div class="d-flex gg-12 flex-wrap">
                <div class="d-flex align-items-center" *ngIf="project?.expertLevels?.length">
                  <mat-icon [svgIcon]="svgIconsEnum.Expert" class="text-gray-600 mr-4"></mat-icon>
                  <div class="fs-14 fw-500 text-black">{{(project?.expertLevels || []) | tptExpertLevels}}</div>
                </div>
                <div class="d-flex align-items-center" *ngIf="project.country">
                  <mat-icon [svgIcon]="svgIconsEnum.Location" class="text-gray-600 w-14 mr-8"></mat-icon>
                  <div class="fs-14 fw-500 text-black">{{getTranslation(project?.country)}}</div>
                </div>
                <div *ngIf="project.languages?.length" class="d-flex align-items-center">
                  <mat-icon [svgIcon]="svgIconsEnum.Language" class="mr-8 text-gray-600"></mat-icon>
                  <div class="fs-14 text-black fw-500">
                    {{getLangByCode(project.languages[0])}}
                    - {{'CREATE_PROJECT.STEP3.' + project.languages[0].level | translate}}
                  </div>
                  <span *ngIf="project.languages.length > 1"
                        (click)="$event.stopPropagation()"
                        [matMenuTriggerFor]="langMenu"
                        class="d-flex align-items-center more-links">, еще {{project.languages.length - 1}}
                    <mat-icon [svgIcon]="svgIconsEnum.Expand"></mat-icon>
                  </span>
                  <mat-menu #langMenu="matMenu">
                    <div class="py-12 px-16">
                    <span *ngFor="let lang of project.languages.slice(1)" class="fs-14 text-black fw-500">
                      {{getLangByCode(lang)}} - {{'CREATE_PROJECT.STEP3.' + lang.level | translate}}
                    </span>
                    </div>
                  </mat-menu>
                </div>

              </div>
              <div class="d-flex flex-wrap gg-12 mt-8">
                <tpt-chip class="chip" type="gray" size="small"
                          *ngFor="let skill of specSkills(project)">
                  {{getTranslation(skill) || skill}}
                </tpt-chip>
              </div>
            </div>

          <div class="bottom-row">
            <div class="amount fw-700 text-gray-800">{{project.budget | number}} ₽</div>
            <div class="buttons-row ">
              <button mat-button class="copy-btn text-black"
                      #tooltip="matTooltip"
                      (click)="$event.stopPropagation(); copyJobLink(project, tooltip)"
                      matTooltip="Ссылка скопирована в буфер обмена"
                      matTooltipClass="custom-tooltip"
                      [matTooltipPosition]="'above'"
                      [matTooltipDisabled]="true"
                      [matTooltipShowDelay]="0"
              >
                <mat-icon [svgIcon]="svgIconsEnum.CopyLink"></mat-icon>
              </button>
              <ng-container *ngIf="!isEmployer">
                <tc-button type="primary"
                           size="medium"
                           *ngIf="!project.jobConversations?.length"
                           [disabled]="disableButton" class="send-request"
                           [class.grow]="!['PROPOSAL_SUBMITTED', 'INVITED'].includes(project.jobConversations?.[0]?.participationState)"
                           (click)="$event.stopPropagation()"
                           matTooltip="Для отправки заявки на участие в проектах выполните привязку профиля к ФНС."
                           matTooltipClass="custom-tooltip"
                           matTooltipPosition="above"
                           [matTooltipDisabled]="!disableButton"
                           [matTooltipShowDelay]="200"
                           (onClick)="sendProposal(project)">
                  <mat-icon [svgIcon]="svgIconsEnum.PaperPlane" class="send-icon"></mat-icon>
                  {{'SEARCH.SEND_REQUEST' | translate}}
                </tc-button>

                <div *ngIf="['PROPOSAL_SUBMITTED', 'INVITED'].includes(project.jobConversations?.[0]?.participationState)"
                     class="request-sent"
                     (click)="$event.stopPropagation()">
                  <mat-icon [svgIcon]="svgIconsEnum.PaperPlane" class="send-icon mr-4"></mat-icon>
                  <span class="desktop">{{(project.jobConversations?.[0]?.participationState === 'PROPOSAL_SUBMITTED' ? 'SEARCH.REQUEST_SENT' : 'SEARCH.INVITE_RECEIVED') | translate}}</span>
                </div>

                <tc-button type="alternative"
                           size="medium"
                           *ngIf="['PROPOSAL_SUBMITTED', 'INVITED'].includes(project.jobConversations?.[0]?.participationState) &&
                                      project?.jobConversations?.[0]?.chatId"
                           class="go-to-chat"
                           (click)="$event.stopPropagation()"
                           (onClick)="openChat(project?.jobConversations?.[0]?.chatId)"
                >
                  <mat-icon [svgIcon]="svgIconsEnum.Chat"></mat-icon>
                  Перейти в чат
                </tc-button>
              </ng-container>
            </div>
          </div>

          <div class="fs-12 fw-500 text-gray-600 mobile mt-16">{{'SEARCH.PUBLISHED' | translate}} {{project.publishDate | timeAgo}}</div>
        </mat-card>
    </ng-container>

    <ng-template #notFound>
      <div class="not-found">
        <img src="assets/images/empty-list.png"/>
        <div class="text-black fw-600 mb-8">Ничего не найдено</div>
        <div class="fs-14 fw-500 text-gray-600 mb-28">Пожалуйста, попробуйте искать по другим ключевым словам или изменить настройки фильтров</div>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #projectsLoader>
    <ngx-skeleton-loader
      count="9"
      animation="progress"
      [theme]="{
          'border-radius': '4px',
          height: '264px',
          background: '#eff1f6',
          'box-shadow': '0 4px 4px rgba(0, 0, 0, 0.1)'
        }"
    ></ngx-skeleton-loader>
  </ng-template>
</div>

<job-apply-error-dialog></job-apply-error-dialog>

<app-warning-dialog></app-warning-dialog>
