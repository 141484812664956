<div class="page-root">

  <div class="welcome-wrapper">
    <mat-icon class="info-icon mr-24" [svgIcon]="svgIconsEnum.InfoIcon"></mat-icon>
    <div>
      <div class="title">Добро пожаловать на Talent Cloud!</div>
      <div class="text">У вас ограниченный доступ, чтобы получить полный доступ:<br/>
        - Свяжите ваш профиль самозанятого с профилем TPT<br/>
        - Внесите свои данные в раздел “Юридические данные”<br/>
        и&nbsp;“Банковские реквизиты” в профиле</div>
    </div>
  </div>

    <div class="card mb-20" matRipple (click)="radioButton = 'SETTINGS'"
         [class.active]="radioButton === 'SETTINGS'">
      <mat-icon [svgIcon]="svgIconsEnum.SidebarFns" class="mr-16"></mat-icon>
      <div>
        <div class="card-title">Заполнить профиль</div>
        <div class="card-text">Получите полный доступ к платформе</div>
      </div>
    </div>


    <div class="card" matRipple (click)="radioButton = 'SEARCH'"
         [class.active]="radioButton === 'SEARCH'">
      <mat-icon [svgIcon]="svgIconsEnum.SidebarHome" class="mr-16"></mat-icon>
      <div>
        <div class="card-title">Перейти к поиску проектов</div>
        <div class="card-text">Познакомьтесь с платформой</div>
      </div>
    </div>

  <div class="checkboxes-wrapper mt-48">
    <tc-checkbox [(ngModel)]="form.userAgreement">
      Я принимаю условия <a target="_blank">Пользовательского соглашения</a>
    </tc-checkbox>

    <tc-checkbox [(ngModel)]="form.privacyPolicy">
      Я даю согласие на обработку моей персональной информации на условиях, определенных
      <a target="_blank">Политикой конфиенциальности</a>
    </tc-checkbox>

    <tc-checkbox [(ngModel)]="form.transfer">
      Я даю согласие на перенос моих данных из личного кабинета Creative Objects
    </tc-checkbox>
  </div>


  <div class="bottom-actions">
    <tc-button type="tertiary" size="large"
                routerLink="/creative-objects/app/settings">Назад</tc-button>
    <tc-button type="primary" class="ml-12" size="large" (onClick)="start()"
                [disabled]="!form.privacyPolicy || !form.transfer || !form.userAgreement">Начать</tc-button>
  </div>
</div>
