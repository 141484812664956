<div class="register-root">
  <div class="register-wrap">
    <div class="register">
      <div class="register-column" *ngIf="registerForm">
        <mat-icon *ngIf="registerSuccess" [svgIcon]="svgIconsEnum.HeaderLogo" class="logo-full"></mat-icon>

        <h2 class="register-title" (click)="test()">{{ 'REGISTER_COMPONENT.ACCOUNT_CREATION' | translate }}</h2>

        <form *ngIf="!registerSuccess">
          <div class="fs-14 fw-500 text-gray-600 mb-32">
            {{inviteCode ? 'Для создание аккаунта введите почту и придумайте пароль' : 'Для создания аккаунта выберите роль и задайте пароль'}}
          </div>
          <mat-radio-group *ngIf="!inviteCode" [formControl]="registerForm.role"
                           [class.invalid]="registerForm.role.invalid && registerForm.role.touched"
                           class="mb-24">
            <mat-radio-button value="EMPLOYER" color="primary" (click)="registerForm.role.setValue('EMPLOYER')"
                              labelPosition="before">
              <mat-icon [svgIcon]="svgIconsEnum.ClientRole"></mat-icon>
              {{'REGISTER_COMPONENT.I_AM_CLIENT' | translate}}</mat-radio-button>
            <mat-radio-button value="FREELANCER" color="primary" (click)="registerForm.role.setValue('FREELANCER')"
                              labelPosition="before">
              <mat-icon [svgIcon]="svgIconsEnum.TalentRole"></mat-icon>
              {{'REGISTER_COMPONENT.I_AM_TALENT' | translate}}</mat-radio-button>
          </mat-radio-group>

          <div class="mb-4">
            <tc-input [control]="registerForm.email" [icon]="svgIconsEnum.EnvelopeOutline">
              <ng-container label>{{ 'REGISTER_COMPONENT.EMAIL' | translate }}</ng-container>
            </tc-input>
          </div>

          <div style="position: relative">
            <tc-input [control]="registerForm.password"
                      class="password-input"
                      [type]="showPass ? 'text' : 'password'"
                      (onFocus)="showTooltip = true"
                      (onBlur)="showTooltip = false"
            >
              <ng-container label>{{ 'REGISTER_COMPONENT.PASSWORD' | translate }}</ng-container>
              <ng-container icon>
                <mat-icon class="input-icon"
                          (click)="showPass = !showPass"
                          [svgIcon]="showPass ? svgIconsEnum.EyeOpen : svgIconsEnum.EyeClose"></mat-icon>
              </ng-container>
            </tc-input>

            <tc-password-strength-tooltip [passwordComponent]="passwordComponent"
                                          [showTooltip]="showTooltip"
            ></tc-password-strength-tooltip>
          </div>

          <div class="strength-bar" [class.visible]="registerForm.password?.touched || registerForm.password?.dirty">
            <password-strength [password]="registerForm.password.value"
                               #passwordComponent
                               [min]="minPasswordLength"
                               class="mb-24"
            ></password-strength>
          </div>

          <div class="checkboxes-wrapper mb-20">
            <tc-checkbox [ngModel]="registerForm.userAgreement.value"
                         [ngModelOptions]="{standalone: true}"
                         (ngModelChange)="registerForm.userAgreement.setValue($event)"
            >
              {{ 'REGISTER_COMPONENT.I_ACCEPT' | translate }}
              <a (click)="$event.stopPropagation()" [routerLink]="'/legal/terms'"
                 target="_blank">{{ 'REGISTER_COMPONENT.RULES_OF_USE' | translate }}</a>
            </tc-checkbox>
            <tc-checkbox [ngModel]="registerForm.privacyPolicy.value"
                         [ngModelOptions]="{standalone: true}"
                         (ngModelChange)="registerForm.privacyPolicy.setValue($event)"
            >
              {{ 'REGISTER_COMPONENT.I_GIVE_CONSENT' | translate }}
              <a (click)="$event.stopPropagation()" [routerLink]="'/legal/privacy'"
                 target="_blank">{{ 'REGISTER_COMPONENT.PRIVACY_POLICY' | translate }}</a>
            </tc-checkbox>
          </div>

          <tc-button type="primary" size="x-large" class="full-width"
                     [disabled]="buttonDisabled()"
                     (onClick)="register()"
          >
            {{'REGISTER_COMPONENT.CREATE_ACCOUNT' | translate}}
          </tc-button>

          <tc-button
            type="tertiary"
            size="x-large"
            class="full-width mt-12"
            [routerLink]="['/login']"
            [queryParams]="activatedRoute.snapshot.queryParams"
          >
            {{'LOGIN_COMPONENT.HAVE_ACC' | translate}}
          </tc-button>
        </form>

        <div *ngIf="registerSuccess" class="success-wrapper">
          <div class="message-title">{{'REGISTER_COMPONENT.SUCCESS_TITLE' | translate}}</div>
          <div class="message-text">{{'REGISTER_COMPONENT.SUCCESS_TEXT' | translate}}
            <span class="email">{{registerForm.email.value}}</span>
          </div>
        </div>
      </div>

    </div>
  </div>
  <app-auth-pages-footer></app-auth-pages-footer>
</div>

<tpt-signup-error-dialog></tpt-signup-error-dialog>
