<tpt-label *ngIf="showLabel">
  <ng-content select="[label]"></ng-content>
</tpt-label>

<mat-form-field
  appearance="outline"
  [class.-error-state]="errorState"
>
  <textarea
    id="textarea"
    matInput
    [formControl]="control"
    [placeholder]="placeholder"
    (keyup)="onKeyup.emit()"
  ></textarea>

  <mat-hint [align]="hintAlign">
    <ng-content select="[hint]"></ng-content>
  </mat-hint>

  <mat-error [class.-invisible]="!errorState">
    {{ error | translate}}
  </mat-error>
</mat-form-field>
