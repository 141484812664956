<div class="icon-wrap">
  <mat-icon *ngIf="type === 'success'" [svgIcon]="svgIconsEnum.DialogIconSuccess"></mat-icon>
  <mat-icon *ngIf="type === 'error'" [svgIcon]="svgIconsEnum.DialogIconError"></mat-icon>
</div>

<div class="fs-20 fw-700 text-black mb-16" *ngIf="title" [innerHTML]="title | translate:params"></div>

<div *ngIf="message" class="alert fs-14 fw-500 text-gray-600"
     [ngClass]="{'alert-success': type === 'success', 'alert-danger': type === 'error' }">
  <span [innerHTML]="message | translate:params"></span>
</div>
<br>
<tc-button type="tertiary" size="large" class="full-width"
        (onClick)="dialogRef.close(false)">{{'BUTTON.CLOSE' | translate}}</tc-button>
