import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Clipboard } from '@angular/cdk/clipboard';

import { HireFreelancerDialogComponent } from '../../dialogs/hire-freelancer-dialog/hire-freelancer-dialog.component';
import { ProfileModel } from '../../../../../models/profile.model';
import { SvgIconsEnum } from '../../../../../types/svg-icons.enum';
import { FreelancerLegalStatusEnum } from '../../../../../models/legalStatuses.enum';
import { ProfilesApiService } from '../../../../../services/profiles.api.service';
import { RouteHelper } from '../../../../../helpers/route.helper';
import { ProfileService } from '../../../../../services/profile.service';
import { SpecializationModel } from '../../../../../models/specialization.model';
import { ChatsDialogComponent } from '../../../../common-dialogs/components/chats-dialog/chats-dialog.component';
import { ProjectApiService } from '../../../../../services/project.api.service';
import { lastValueFrom } from 'rxjs';
import { WarningDialogComponent } from '@/modules/common-dialogs/components/warning-dialog/warning-dialog.component';
import { showTooltip } from '@/helpers/tooltip.helper';

@Component({
  selector: 'tpt-freelancer-search-card',
  templateUrl: './freelancer-search-card.component.html',
  styleUrls: ['./freelancer-search-card.component.scss']
})
export class FreelancerSearchCardComponent implements OnInit {

  @ViewChild(HireFreelancerDialogComponent) hireFreelancerDialog: HireFreelancerDialogComponent;
  @ViewChild(ChatsDialogComponent) chatsDialogComponent: ChatsDialogComponent;
  @ViewChild(WarningDialogComponent) warningDialogComponent: WarningDialogComponent;

  @Input() freelancer: ProfileModel;
  @Input() skillsFilter: UntypedFormArray;

  public visited = false;
  public svgIconsEnum = SvgIconsEnum;
  public freelancerLegalStatusEnum = FreelancerLegalStatusEnum;
  public empProjects = [];

  get isEmployer(): boolean {
    if (!this.profileService.currentProfile) { return; }
    return this.profileService.currentProfile.isEmployer();
  }

  constructor(
    private translate: TranslateService,
    public profileApiService: ProfilesApiService,
    public profileService: ProfileService,
    private route: ActivatedRoute,
    private projectService: ProjectApiService,
    private router: Router,
    private routeHelper: RouteHelper,
    private clipboard: Clipboard,
  ) { }

  ngOnInit(): void {
    const visitedTalentCards = JSON.parse(localStorage.getItem('visitedTalentCards') || '{}');

    const keys = Object.keys(visitedTalentCards);
    keys.forEach(key => {
      if (Date.now() > visitedTalentCards[key]) {
        delete visitedTalentCards[key];
      }
    });

    localStorage.setItem('visitedTalentCards', JSON.stringify(visitedTalentCards));
    if (Object.keys(visitedTalentCards).some(key => +key === +this.freelancer.id)) {
      this.visited = true;
    }
  }

  public getTranslation(item): string {
    if (!item) { return; }
    if (this.translate.currentLang === 'ru') {
      return item.nameRu;
    }
    return item.name;
  }

  public openCardDetails(freelancer): void {
    const visitedTalentCards = JSON.parse(localStorage.getItem('visitedTalentCards') || '{}');
    const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;
    visitedTalentCards[freelancer.id] = Date.now() + threeDaysInMs;
    localStorage.setItem('visitedTalentCards', JSON.stringify(visitedTalentCards));
    this.visited = true;

    const url = this.router.serializeUrl(
      this.router.createUrlTree(this.routeHelper.userPage(freelancer.id))
    );

    window.open(url, '_blank');
  }

  public openChatsDialog() {
    if (!this.filterChats()?.length) {
      return;
    }
    this.chatsDialogComponent.open(this.filterChats());
  }

  public getChipStyle(skill): boolean {
    const skillsFilter = this.skillsFilter.value;

    return skillsFilter.some(item => item.id === skill.id);
  }

  public filterChats(): any[] {
    if (!this.freelancer?.jobParticipating?.length) {
      return [];
    }

    const filteredChats = this.freelancer?.jobParticipating?.filter(item =>
      ['INIT'].includes(item.jobState))
      .filter(item =>
        ['INVITED', 'PROPOSAL_SUBMITTED', 'PARTICIPANT'].includes(item.jobParticipationState)
      );

    return filteredChats || [];
  }

  blurCard(id): void {
    const visitedTalentCards = JSON.parse(localStorage.getItem('visitedTalentCards') || '{}');
    const threeDaysInMs = 3 * 24 * 60 * 60 * 1000;
    visitedTalentCards[id] = Date.now() + threeDaysInMs;
    localStorage.setItem('visitedTalentCards', JSON.stringify(visitedTalentCards));
    this.visited = true;
  }

  async hireFreelancer(): Promise<void> {
    this.empProjects = await lastValueFrom(this.projectService.getInitiatedJobs());
    this.hireFreelancerDialog.open(this.freelancer, this.empProjects);
  }

  public getLink(fileId: string): string {
    return this.profileApiService.getReviewsLink(fileId);
  }

  public disableBtn(talent: ProfileModel): boolean {
    return !talent.permissions?.includes('BANK_DETAILS_VALIDATED') ||
      !talent.permissions.includes('LEGAL_INFO_VALIDATED') ||
      talent.selfEmployedStatus !== 'ACTIVE';
  }

  public getYears(val): number {
    if (val < 12) {
      return 0;
    }
    return Math.floor(val / 12);
  }

  public getMonths(val): number {
    return val % 12;
  }

  stop(e) {
    e.stopPropagation();
  }

  public specSkills(specialization: SpecializationModel) {
    const skills = specialization.skills || [];
    const customSkills = specialization.customSkills || [];

    return [...skills, ...customSkills];
  }

  public copyProfilePageLink(freelancer, tooltip): void {
    let baseUrl = window.location.href;
    baseUrl = baseUrl.split('/').slice(0, 3).join('/');

    const userUrl = this.router.serializeUrl(
      this.router.createUrlTree(this.routeHelper.userPage(freelancer.id))
    );

    const fullUrl = `${baseUrl}${userUrl}`;

    this.clipboard.copy(fullUrl);
    showTooltip(tooltip);
  }

  public mainSpec(specs: SpecializationModel[]): SpecializationModel {
    return specs.find(spec => spec.main) || null;
  }
}
